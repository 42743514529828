body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;

  height: 100%;
  width: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.infinite-scroll-component {
  overflow: unset !important;
}

.ptr {
  height: max-content;
  overflow: visible !important;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.gm-style .gm-style-iw {
  background-color: #3939ff !important;
  width: "20px" !important;
  max-height: "20px" !important;
  font-family: "Poppins" !important;
}

.gm-style .gm-style-iw-tc::after {
  background-color: #3939ff !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
  background-color: #3939ff !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track {
  background-color: #3939ff !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background-color: #3939ff !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-thumb {
  background-color: #3939ff !important;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}
